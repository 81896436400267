import "../Textbox/Textbox.scss";

/*
  Text Box component 
*/

const Textbox = ({
  id,
  label,
  icon,
  alt,
  placeholder,
  onChange,
  value,
  classname,
  name,
  type,
}) => {
  return (
    <div className="textbox" id={id}>
      <label className="textbox__label">{label}</label>
      <div className="textbox__container">
        <input
          className={`textbox__text ${classname}`}
          type={type}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  );
};

export default Textbox;
