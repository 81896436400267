import React from "react";
import "./ActiveTasksPage.scss";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import TableActiveTask from "../../components/TableActiveTask/TableActiveTask";
import PopupAddActiveTask from "../../components/PopupAddActiveTask/PopupAddActiveTask";
import Button from "../../components/Buttons/Buttons";
import {
  getProfile,
  getProjectList,
  getActiveTaskList,
  postActiveTaskList,
  deleteActiveTaskList,
  editActiveTaskList,
} from "../../utils/apiUtils.mjs";
import Calendar from "../../components/Calendar/Calendar";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import PopupCalendar from "../../components/PopupCalendar/PopupCalendar";
import PopupCalendarEdit from "../../components/PopupCalendarEdit/PopupCalendarEdit";

const ActiveTasksPage = () => {
  const [sorting, setSorting] = useState({ item: "id", order: "asc" });
  const [tbodyData, setTbodyData] = useState(null);
  const [addFormData, setAddFormData] = useState({});
  const [editFormData, setEditFormData] = useState({});
  const [editRowID, setEditRowID] = useState(null);
  const [user, setUser] = useState(null);
  const [activeTaskList, setActiveTaskList] = useState(null);
  const [projectList, setProjectList] = useState(null);
  const [failedAuth, setFailedAuth] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCalendarAddModal, setShowCalendarAddModal] = useState(false);
  const [showCalendarEditModal, setShowCalendarEditModal] = useState(false);
  const [selectedAddDate, setSelectedAddDate] = useState(null);
  const [selectedEditDate, setSelectedEditDate] = useState(null);
  const [timesheetCalendarList, setTimesheetCalendarList] = useState(null);
  const Navigate = useNavigate();

  /* Variables */
  const theadData = [
    "Project Manager",
    "Project Name",
    "Task Description",
    "Actions",
  ];

  /* Functions */
  const sortTable = (newSorting) => {
    // Sorts table in asscending or descending order
    setSorting(newSorting);
  };

  const getProfileFunction = async () => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      return setFailedAuth(true);
    }

    try {
      // Get the data from the API
      const { data } = await getProfile(token);
      setUser(data);
    } catch (error) {}
  };

  const getProjectListFunction = async () => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      return setFailedAuth(true);
    }

    try {
      // Get the data from the API
      const { data } = await getProjectList(token);
      setProjectList(data);
    } catch (error) {}
  };

  const getActiveTaskListFunction = async () => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      return setFailedAuth(true);
    }

    try {
      // Get the data from the API
      const { data } = await getActiveTaskList(token);
      setActiveTaskList(data);
      setTbodyData(data);
    } catch (error) {}
  };

  const postActiveTaskListFunction = async ({
    user_id,
    project_id,
    task_description,
    start,
    end,
  }) => {
    const token = sessionStorage.getItem("token");

    user_id = user.id;
    if (!token) {
      return setFailedAuth(true);
    }
    try {
      // Get the data from the API

      await postActiveTaskList(
        token,
        user_id,
        project_id,
        task_description,
        start,
        end
      );
      await getActiveTaskListFunction();
      setShowModal(false);
    } catch (error) {}
  };

  const deleteActiveTaskListFunction = async (TaskID) => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      return setFailedAuth(true);
    }
    try {
      const { data } = await deleteActiveTaskList(token, TaskID);
      await getActiveTaskListFunction();
      return data;
    } catch (error) {}
  };

  const editActiveTaskListFunction = async ({
    id,
    user_id,
    project_id,
    task_description,
    start,
    end,
  }) => {
    const token = sessionStorage.getItem("token");

    user_id = user.id;
    if (!token) {
      return setFailedAuth(true);
    }
    try {
      // Get the data from the API

      await editActiveTaskList(
        token,
        id,
        user_id,
        project_id,
        task_description,
        start,
        end
      );
      await getActiveTaskListFunction();
    } catch (error) {}
  };

  /* Handle Events */
  const handleAddFormChange = (event) => {
    // Form submit event handler

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };

  const handleEditFormChange = (event) => {
    // Edit form submit event handler

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };

  const handleEditClick = (event, row) => {
    // Edit button event handler
    event.preventDefault();
    setEditRowID(row.id);

    const formValues = {
      id: row.id,
      user_id: row.user_id,
      project_id: row.project_id,
      task_description: row.task_description,
      start: row.start,
      end: row.end,
    };
    setEditFormData(formValues);
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();

    const selectedProjectID = projectList.find((projects) => {
      return projects.project_name === event.target.add_project.value;
    });
    const newRowID = {
      id: addFormData.id,
      user_id: addFormData.user_id,
      project_id: selectedProjectID.id,
      task_description: addFormData.task_description,
      start: addFormData.start,
      end: addFormData.end,
    };

    const newRow = [...tbodyData, newRowID];
    postActiveTaskListFunction(newRowID);
    setAddFormData({});
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();

    const editedRow = {
      id: editRowID,
      user_id: editFormData.user_id,
      project_id: editFormData.project_id,
      task_description: editFormData.task_description,
      start: editFormData.start,
      end: editFormData.end,
    };
    const newRow = [...tbodyData];

    const index = tbodyData.findIndex((rowID) => {
      return rowID.id === editRowID;
    });

    newRow[index] = editedRow;

    editActiveTaskListFunction(editedRow);
    setEditRowID(null);
  };

  const handleCancelClick = () => {
    setEditRowID(null);
  };

  const handleDeleteClick = (deleterowID) => {
    const newRow = [...tbodyData];
    const index = tbodyData.findIndex((rowID) => {
      return rowID.id === deleterowID;
    });
    newRow.splice(index, 1);

    deleteActiveTaskListFunction(deleterowID);
  };

  const handleCancel = () => {
    // Handle Cancel Form resets whole form
    setAddFormData({});
    setShowModal(false);
  };

  const handleAddProject = () => {
    setShowModal(true);
  };

  // const handleAddTask = (event) => {
  //   event.preventDefault();
  //   // const selectedTask = userActiveTaskList.find((task) => {
  //   //   return task.id == event.target.add_task.value;
  //   // });

  //   // selectedTask.start = selectedDate;

  //   // editActiveTaskListFunction(selectedTask);

  //   console.log(event.target.findProjectValue.value);
  //   console.log(event.target.descriptionValue.value);
  //   setShowCalendarModal(false);
  // };

  // const handleCancelTask = () => {
  //   setShowCalendarModal(false);
  // };

  const dateEventHandler = (info, setShowCalendarModal) => {
    setSelectedAddDate(info.dateStr);
    setShowCalendarModal(true);
  };

  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  }

  const calendarTasks = () => {
    const date = [];

    if (activeTaskList !== null) {
      for (let i = 0; i < activeTaskList.length; i++) {
        if (activeTaskList[i].user_id === user.id) {
          date.push({
            title: `${findProject(activeTaskList[i].project_id)}\n${
              activeTaskList[i].task_description
            }\n${activeTaskList[i].project_id}`,
            date: `${activeTaskList[i].start.slice(0, 10)}`,
          });
        }
      }
    }
    return date;
  };

  const findProject = (id) => {
    for (let i = 0; i < projectList.length; i++) {
      if (projectList[i].id === id) {
        return `${projectList[i].project_name}`;
      }
    }
  };

  useEffect(() => {
    getProjectListFunction();
    getProfileFunction();
    getActiveTaskListFunction();
  }, [showCalendarAddModal, showCalendarEditModal]);

  if (failedAuth) {
    return (
      <main className="active-tasks-page">
        <p>You must be logged in to see this page.</p>
        <p>
          <Link to="/Login">Log in</Link>
        </p>
      </main>
    );
  }

  if (!user) {
    return (
      <main className="active-tasks-page">
        <p>Loading...</p>
      </main>
    );
  }

  if (user && activeTaskList && projectList) {
    // const userActiveTaskList = activeTaskList.filter((activeTask) => {
    //   return user.id === activeTask.user_id;
    // });
    return (
      <main className="active-tasks-page">
        {/* <div className="active-tasks-page__sub-section">
          <div className="active-tasks-page__sub-container">
            <h2 className="active-tasks-page__sub-header">Active Tasks List</h2>
          </div>
          <TableActiveTask
            theadData={theadData}
            tbodyData={userActiveTaskList}
            sorting={sorting}
            sortTable={sortTable}
            customClass="table"
            user={user}
            projectList={projectList}
            activeTaskList={activeTaskList}
            editRowID={editRowID}
            editFormData={editFormData}
            handleEditClick={handleEditClick}
            handleEditFormChange={handleEditFormChange}
            handleEditFormSubmit={handleEditFormSubmit}
            handleCancelClick={handleCancelClick}
            handleDeleteClick={handleDeleteClick}
          />
          {showModal && (
            <PopupAddActiveTask
              confirmText="Add Task"
              cancelText="Cancel"
              addFormData={addFormData}
              activeTaskList={activeTaskList}
              handleAddFormSubmit={handleAddFormSubmit}
              handleAddFormChange={handleAddFormChange}
              setTrigger={setShowModal}
              handleCancel={handleCancel}
              projectList={projectList}
            />
          )}
          <div className="timesheet-page__sub-container">
            <Button
              id={""}
              icon={""}
              alt={""}
              value={"Add Task"}
              onclick={handleAddProject}
              type={"button"}
              className={""}
            />
          </div>
        </div> */}
        <div className="active-tasks-page__sub-section-calendar">
          <div className="active-tasks-page__sub-container-calendar">
            {/* <Calendar
              view={"dayGridMonth"}
              userActiveTaskList={userActiveTaskList}
              editActiveTaskListFunction={editActiveTaskListFunction}
              getActiveTaskListFunction={getActiveTaskListFunction}
            /> */}
          </div>
          <div className="active-tasks-page__sub-container-calendar">
            <h2 className="active-tasks-page__sub-header">Active Tasks</h2>
            {/* <Calendar
              view={"dayGridWeek"}
              userActiveTaskList={activeTaskList}
              editActiveTaskListFunction={editActiveTaskListFunction}
              getActiveTaskListFunction={getActiveTaskListFunction}
            /> */}
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView={"dayGridWeek"}
              handleWindowResize={true}
              expandRows={true}
              weekends={true}
              selectable={true}
              dateClick={(info) => {
                // console.log("Clicked on: " + info.dateStr);
                // console.log(
                //   "Coordinates: " +
                //     info.jsEvent.pageX +
                //     "," +
                //     info.jsEvent.pageY
                // );
                // change the day's background color just for fun
                // info.dayEl.style.backgroundColor = "red";
                // console.log("Current view: " + info.view.type);
                dateEventHandler(info, setShowCalendarAddModal);
              }}
              className="Calendar"
              events={calendarTasks()}
              eventContent={renderEventContent}
              eventClick={(info) => {
                // change the border color just for fun
                // info.el.style.borderColor = "red";

                let project_name = "";
                let description = "";
                let project_id;
                let pos = 0;

                for (; pos < info.event.title.length; pos++) {
                  if (info.event.title[pos] === "\n") {
                    pos++;
                    break;
                  }
                  project_name += info.event.title[pos];
                }

                for (; pos < info.event.title.length; pos++) {
                  if (info.event.title[pos] === "\n") {
                    pos++;
                    break;
                  }
                  description += info.event.title[pos];
                }

                for (; pos < info.event.title.length; pos++) {
                  if (info.event.title[pos] === "\n") {
                    pos++;
                    break;
                  }
                  project_id += info.event.title[pos];
                }

                project_id = project_id.slice(9, project_id.length);
                project_id = Number(project_id);

                setSelectedEditDate({
                  date: info.event.startStr,
                  project_name,
                  description,
                  project_id,
                });
                setShowCalendarEditModal(true);
              }}
            />
            {showCalendarAddModal && (
              <PopupCalendar
                userID={user.id}
                cancelText="Cancel"
                confirmText="Add to Calendar"
                setState={setShowCalendarAddModal}
                initialState={showCalendarAddModal}
                selectedDate={selectedAddDate}
                headerText="Add a Task to Calendar"
                subHeaderText={["Selected Date"]}
                data={[projectList, activeTaskList]}
              />
            )}
            {showCalendarEditModal && (
              <PopupCalendarEdit
                userID={user.id}
                cancelText="Cancel"
                confirmText="Save"
                setState={setShowCalendarEditModal}
                initialState={showCalendarEditModal}
                selectedDate={selectedEditDate}
                headerText="Edit a Task to Calendar"
                subHeaderText={[
                  "Selected Date",
                  "Project Name",
                  "Task Description",
                ]}
                data={[projectList, activeTaskList]}
              />
            )}
          </div>
        </div>
      </main>
    );
  }
};

export default ActiveTasksPage;
