import "./PopupTimesheetEditCalendar.scss";
import React, { useState } from "react";
import Textbox from "../Textbox/Textbox";
import Button from "../Buttons/Buttons";
import {
  getTimesheetCalendarList,
  postTimesheetCalendarList,
  deleteTimesheetCalendarList,
  editTimesheetCalendarList,
  putProjectUpdate,
} from "../../utils/apiUtils.mjs";

const PopupTimesheetEditCalendar = ({
  userID,
  cancelText,
  confirmText,
  setState,
  initialState,
  selectedDate,
  headerText,
  subHeaderText,
  data,
}) => {
  const [searchProject, setSearchProject] = useState({
    project_name: selectedDate.project_name,
    project_id: selectedDate.project_id,
    hours: selectedDate.hours,
    task_description: selectedDate.description,
  });
  const [isProjectIDValid, setIsProjectIDValid] = useState(false);
  const [isProjectHoursValid, setIsProjectHoursValid] = useState(false);
  const [isProjectTaskDescriptionValid, setIsProjectTaskDescriptionValid] =
    useState(false);
  const [showList, setShowList] = useState(true);

  const handleEditProject = (event) => {
    if (searchProject.hours === "") {
      setIsProjectHoursValid(true);
      event.preventDefault();
    }
    if (searchProject.project_id === "") {
      setIsProjectIDValid(true);
      event.preventDefault();
    }
    if (searchProject.task_description === "") {
      setIsProjectTaskDescriptionValid(true);
      event.preventDefault();
    }
    if (
      searchProject.hours !== "" &&
      searchProject.project_id !== "" &&
      searchProject.task_description !== ""
    ) {
      let TimesheetID = data[1].filter((timesheet) => {
        if (
          timesheet.description === selectedDate.description &&
          timesheet.hours === selectedDate.hours &&
          timesheet.date == selectedDate.date
        ) {
          return timesheet;
        }
      });
      TimesheetID = TimesheetID[0].id;
      editTimesheetCalendarListFunction(
        userID,
        TimesheetID,
        searchProject.project_id,
        selectedDate.date,
        searchProject.task_description,
        Number(searchProject.hours)
      );
      event.preventDefault();
    }
  };

  const handleDelete = async (TimesheetID, ProjectID) => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      return setState(false);
    }
    try {
      const { data } = await deleteTimesheetCalendarList(token, TimesheetID);
      await putProjectUpdateFunction(ProjectID);
      setState(false);
      return data;
    } catch (error) {}
  };

  const editTimesheetCalendarListFunction = async (
    user_id,
    TimesheetID,
    project_id,
    date,
    task_description,
    hours
  ) => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      setState(false);
    }

    try {
      // Get the data from the API
      await editTimesheetCalendarList(
        token,
        TimesheetID,
        user_id,
        project_id,
        date,
        task_description,
        hours
      );
      await putProjectUpdateFunction(project_id);
      setState(false);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const putProjectUpdateFunction = async (ProjectID) => {
    const token = sessionStorage.getItem("token");
    try {
      await putProjectUpdate(token, ProjectID);
    } catch (error) {}
  };

  return (
    <div className="popup">
      <div className="popup__inner">
        <div className="popup__content">
          <h1 className="popup__header">{headerText}</h1>
          <label className="popup__sub-header">
            {`${subHeaderText[0]}: ${selectedDate.date}`}
          </label>
          <form onSubmit={handleEditProject}>
            <div>
              <Textbox
                label={"Find a Project"}
                type={"search"}
                name={"findProjectValue"}
                placeholder={"Enter Project Name"}
                onChange={(e) => {
                  setSearchProject({
                    ...searchProject,
                    project_name: e.target.value,
                    project_id: "",
                  });
                  setIsProjectIDValid(false);
                  setShowList(true);
                }}
                value={searchProject.project_name}
              />
              {isProjectIDValid && (
                <label className="popup__sub-header--modifier">
                  Please select valid project
                </label>
              )}
              {showList && (
                <ul>
                  {data[0]
                    .filter((project) => {
                      if (searchProject.project_name.length === 0) {
                        return;
                      }
                      let temp = 0;
                      for (
                        let i = 0;
                        i < searchProject.project_name.length;
                        i++
                      ) {
                        if (
                          searchProject.project_name[i] ===
                          project.project_name[i]
                        ) {
                          temp++;
                        }
                      }
                      return temp === searchProject.project_name.length;
                    })
                    .map((project) => {
                      return (
                        <li
                          key={project.id}
                          onClick={() => {
                            setSearchProject({
                              ...searchProject,
                              project_name: project.project_name,
                              project_id: project.id,
                            });
                            setShowList(false);
                          }}
                        >
                          {project.project_name}
                        </li>
                      );
                    })}
                </ul>
              )}
              <Textbox
                label={"Hours"}
                type={"number"}
                name={"hoursValue"}
                placeholder={"Enter Hours"}
                onChange={(e) => {
                  setSearchProject({
                    ...searchProject,
                    hours: e.target.value,
                  });
                  setIsProjectHoursValid(false);
                }}
                value={searchProject.hours}
              />
              {isProjectHoursValid && (
                <label className="popup__sub-header--modifier">
                  Please enter hours
                </label>
              )}
              <Textbox
                label={"Task Description"}
                type={"text"}
                name={"descriptionValue"}
                placeholder={"Enter Description"}
                onChange={(e) => {
                  setSearchProject({
                    ...searchProject,
                    task_description: e.target.value,
                  });
                  setIsProjectTaskDescriptionValid(false);
                }}
                value={searchProject.task_description}
              />
              {isProjectTaskDescriptionValid && (
                <label className="popup__sub-header--modifier">
                  Please enter task description
                </label>
              )}
            </div>
            <div className="popup__button-container">
              <Button
                id={""}
                icon={""}
                alt={""}
                value={cancelText}
                onclick={() => {
                  setState(false);
                }}
                type={"button"}
                className={""}
              />
              <Button
                id={""}
                icon={""}
                alt={""}
                value="Delete"
                onclick={() => {
                  let TimesheetID = data[1].filter((timesheet) => {
                    if (
                      timesheet.description === selectedDate.description &&
                      timesheet.hours === selectedDate.hours &&
                      timesheet.date == selectedDate.date
                    ) {
                      return timesheet;
                    }
                  });
                  TimesheetID = TimesheetID[0].id;
                  handleDelete(TimesheetID, selectedDate.project_id);
                }}
                type={"button"}
                className={""}
              />
              <Button
                id={""}
                icon={""}
                alt={""}
                value={confirmText}
                type={"submit"}
                className={""}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PopupTimesheetEditCalendar;
