import "./App.scss";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ActiveTasksPage from "./pages/ActiveTasksPage/ActiveTasksPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import ProjectsPage from "./pages/ProjectsPage/ProjectsPage";
import TimesheetsPage from "./pages/TimesheetsPage/TimesheetsPage";
import Signup from "./pages/Signup/Signup";
import Profile from "./pages/Profile/Profile";
import Role from "./pages/Role/Role";
import Users from "./pages/Users/Users";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/Profile/:UserID" element={<Header />} />
        <Route path="/ActiveTasks/:UserID" element={<Header />} />
        <Route path="/ProjectsPage/:UserID" element={<Header />} />
        <Route path="/TimesheetsPage/:UserID" element={<Header />} />
        <Route path="/Role/:UserID" element={<Header />} />
        <Route path="/Users/:UserID" element={<Header />} />
      </Routes>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/Login" element={<LoginPage />} />
        <Route path="/Signup" element={<Signup />} />
        <Route path="/Profile/:UserID" element={<Profile />} />
        <Route path="/ActiveTasks/:UserID" element={<ActiveTasksPage />} />
        <Route path="/ProjectsPage/:UserID" element={<ProjectsPage />} />
        <Route path="/TimesheetsPage/:UserID" element={<TimesheetsPage />} />
        <Route path="/Role/:UserID" element={<Role />} />
        <Route path="/Users/:UserID" element={<Users />} />
      </Routes>
      <Routes>
        <Route path="/Profile/:UserID" element={<Footer />} />
        <Route path="/ActiveTasks/:UserID" element={<Footer />} />
        <Route path="/ProjectsPage/:UserID" element={<Footer />} />
        <Route path="/TimesheetsPage/:UserID" element={<Footer />} />
        <Route path="/Role/:UserID" element={<Footer />} />
        <Route path="/Users/:UserID" element={<Footer />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
