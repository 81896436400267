import "./PopupProjectAdd.scss";
import React from "react";
import Textbox from "../Textbox/Textbox";
import Button from "../Buttons/Buttons";

const PopupProjectAdd = ({
  confirmText,
  cancelText,
  setTrigger,
  handleAddFormSubmit,
  handleAddFormChange,
  addFormData,
  projectList,
  handleCancel,
  isBudgetValid,
}) => {
  return (
    <div className="popup">
      <div className="popup__inner">
        <div className="popup__content">
          <h1 className="popup__header">Add a project to list</h1>
          <form onSubmit={handleAddFormSubmit}>
            <Textbox
              id={""}
              label={"Project Name"}
              icon={""}
              alt={""}
              placeholder="Enter a project name"
              onChange={handleAddFormChange}
              value={addFormData.project_name}
              classname={""}
              name="project_name"
              type="text"
            />
            <div className="popup__error">
              <Textbox
                id={""}
                label={"Project Budget"}
                icon={""}
                alt={""}
                placeholder="Enter project budget"
                onChange={handleAddFormChange}
                value={addFormData.project_budget}
                classname={""}
                name="project_budget"
                type="text"
              />
              {isBudgetValid && (
                <p className="popup__error-message">
                  Invalid Entry, Please enter number
                </p>
              )}
            </div>
            <div className="popup__button-container">
              <Button
                id={""}
                icon={""}
                alt={""}
                value={cancelText}
                onclick={handleCancel}
                type={"button"}
                className={""}
              />
              <Button
                id={""}
                icon={""}
                alt={""}
                value={confirmText}
                onclick={() => {}}
                type={"submit"}
                className={""}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PopupProjectAdd;
