import React from "react";
import "./TableRowProject.scss";
import { useState } from "react";
import Button from "../../components/Buttons/Buttons";
import PopupProjectTimesheetLog from "../PopupProjectTimesheetLog/PopupProjectTimesheetLog";

const TableRowProject = ({
  id,
  data,
  handleEditClick,
  handleDeleteClick,
  user,
  users,
  projectList,
  activeTaskList,
  timesheetList,
  rolesList,
}) => {
  const [showProjectLogModal, setShowProjectLogModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  let rate = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  // console.log(id);
  // console.log(projectList);
  // console.log(timesheetList);
  // console.log(selectedProject);
  // console.log(users);
  // console.log(showProjectLogModal);

  const findProject = (projectID) => {
    for (let i = 0; i < projectList.length; i++) {
      if (projectList[i].id === projectID) {
        return projectList[i].project_name;
      }
    }
  };

  const findUser = (userID) => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].id === userID) {
        return `${users[i].first_name} ${users[i].last_name}`;
      }
    }
  };

  return (
    <>
      <tr
        key={id}
        onClick={() => {
          //Filters all timesheets for specific project
          const timesheetLog = timesheetList.filter((timesheet) => {
            if (id === timesheet.project_id) {
              return timesheet;
            }
          });
          // console.log(timesheetLog);

          //Finds user

          //Finds project

          setSelectedProject(timesheetLog);
          setShowProjectLogModal(true);
        }}
        className="TableProject__tr"
      >
        <td className="TableProject__td">{`${user.first_name} ${user.last_name}`}</td>
        <td className="TableProject__td">{data.project_name}</td>
        <td className="TableProject__td">{rate.format(data.project_budget)}</td>
        <td className="TableProject__td">
          {rate.format(data.project_labour_effort)}
        </td>
        <td className="TableProject__td">
          {rate.format(data.project_revenue)}
        </td>
        <td className="TableProject__td">
          {" "}
          <Button
            id={""}
            icon={""}
            alt={""}
            value={"Edit"}
            onclick={(event) => handleEditClick(event, data)}
            type={"button"}
            className={""}
          />
          <Button
            id={""}
            icon={""}
            alt={""}
            value={"Delete"}
            onclick={() => handleDeleteClick(data)}
            type={"button"}
            className={""}
          />
        </td>
      </tr>
      {showProjectLogModal && (
        <PopupProjectTimesheetLog
          cancelText="Close"
          setTrigger={setShowProjectLogModal}
          findProject={findProject}
          findUser={findUser}
          projectName={findProject(id)}
          selectedProject={selectedProject}
          data={timesheetList}
          rolesList={rolesList}
          users={users}
        />
      )}
    </>
  );
};

export default TableRowProject;
