import "./Footer.scss";

function Footer() {
  return (
    <>
      <footer className="footer">
        <p className="footer__text">
          © Copyright 2024 Eric Lo. All Rights Reserved.
        </p>
      </footer>
    </>
  );
}

export default Footer;
