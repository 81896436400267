import "./Signup.scss";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Input from "../../components/Input/Input";
import Button from "../../components/Buttons/Buttons";
import Textbox from "../../components/Textbox/Textbox";
import { postSignup } from "../../utils/apiUtils.mjs";

function Signup() {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { REACT_APP_API_URL } = process.env;
  const API_URL = REACT_APP_API_URL;

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await postSignup(
        event.target.email.value,
        event.target.password.value,
        event.target.first_name.value,
        event.target.last_name.value,
        event.target.phone.value,
        event.target.address.value
      );
      navigate("/Login");
    } catch (error) {
      event.target.reset();
      setError(error.response.data.message);
    }
  };

  return (
    <main className="signup-page">
      <div className="signup-page__sub-section">
        <div className="signup-page__sub-container">
          <h2 className="signup-page__sub-header">Enter your info</h2>
        </div>
        <div className="signup-page__sub-container">
          <form onSubmit={handleSubmit} className="signup-page__form">
            <Input
              type="text"
              name="first_name"
              label="First name"
              placeholder={"First Name"}
            />
            <Input
              type="text"
              name="last_name"
              label="Last name"
              placeholder={"Last Name"}
            />
            <Input
              type="text"
              name="phone"
              label="Phone"
              placeholder={"Phone"}
            />
            <Input
              type="text"
              name="address"
              label="Address"
              placeholder={"Address"}
            />
            <Input
              type="text"
              name="email"
              label="Email"
              placeholder={"Email"}
            />
            <Input
              type="password"
              name="password"
              label="Password"
              placeholder={"Password"}
            />
            <Button type="submit" value="Sign up" className="signup__button" />
            <p>
              Have an account? <Link to="/Login">Log in</Link>
            </p>
            <p>{error}</p>
          </form>
        </div>
      </div>
    </main>
  );
}

export default Signup;
