import React from "react";
import "./TableHeaderActiveTask.scss";

const TableHeaderActiveTask = ({ item, sorting, sortTable }) => {
  const isDescSorting = sorting.item === item && sorting.order === "desc";
  const isAscSorting = sorting.item === item && sorting.order === "asc";
  const futureSortingOrder = isDescSorting ? "asc" : "desc";

  return (
    <td
      key={item}
      title={item}
      className="users-table-cell"
      onClick={() => sortTable({ item, order: futureSortingOrder })}
    >
      {item}
      {isDescSorting && <span>down</span>}
      {isAscSorting && <span>up</span>}
    </td>
  );
};

export default TableHeaderActiveTask;
