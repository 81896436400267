import "./Role.scss";
import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  getProfile,
  getRoles,
  deleteRolesList,
  editRolesList,
} from "../../utils/apiUtils.mjs";
import Button from "../../components/Buttons/Buttons";
import PopupRoleAdd from "../../components/PopupRoleAdd/PopupRoleAdd";
import Input from "../../components/Input/Input";

function Role() {
  const [user, setUser] = useState(null);
  const [rolesList, setRolesList] = useState(null);
  const [failedAuth, setFailedAuth] = useState(false);
  const [showAddRoleModal, setShowAddRoleModal] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const [selectedEditedID, setSelectedEditedID] = useState({
    role_name: "",
    class_code: "",
    rate: 0,
  });
  const UserID = useParams();
  const Navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      const token = sessionStorage.getItem("token");

      if (!token) {
        return setFailedAuth(true);
      }

      try {
        // Get the data from the API
        const { data } = await getProfile(token);
        setUser(data);
      } catch (error) {
        setFailedAuth(true);
      }
    };
    const getRolesFunction = async () => {
      const token = sessionStorage.getItem("token");
      if (!token) {
        return setFailedAuth(true);
      }

      try {
        // Get the data from the API
        const { data } = await getRoles(token);
        setRolesList(data);
      } catch (error) {
        setFailedAuth(true);
      }
    };
    getRolesFunction();
    loadData();
  }, [showAddRoleModal]);

  const handleDelete = async (RoleID) => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      return setFailedAuth(true);
    }

    try {
      // Get the data from the API
      await deleteRolesList(token, RoleID);
      const { data } = await getRoles(token);
      setRolesList(data);
    } catch (error) {
      setFailedAuth(true);
    }
  };

  const handleEdit = async (RoleID) => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      return setFailedAuth(true);
    }

    try {
      // Get the data from the API
      await editRolesList(
        token,
        RoleID,
        selectedEditedID.role_name,
        selectedEditedID.class_code,
        selectedEditedID.rate
      );
      const { data } = await getRoles(token);
      setRolesList(data);
      setSelectedID(null);
      setSelectedEditedID({
        role_name: "",
        class_code: "",
        rate: 0,
      });
    } catch (error) {
      setFailedAuth(true);
    }
  };

  if (failedAuth) {
    return (
      <main className="role-page">
        <p>You must be logged in to see this page.</p>
        <p>
          <Link to="/Login">Log in</Link>
        </p>
      </main>
    );
  }

  if (!user && !rolesList) {
    return (
      <main className="role-page">
        <p>Loading...</p>
      </main>
    );
  }

  if (rolesList !== null) {
    return (
      <main className="role-page">
        <div className="role-page__sub-section">
          <div className="role-page__sub-container">
            <h2 className="role-page__sub-header">Roles</h2>
          </div>
          <div className="scrollit">
            <table>
              <thead>
                <tr>
                  <td>Role</td>
                  <td>Classification Code</td>
                  <td>Chargeout Rate</td>
                  <td>Actions</td>
                </tr>
              </thead>
              <tbody>
                {rolesList
                  .sort(function (a, b) {
                    if (a.class_code < b.class_code) {
                      return -1;
                    }
                    if (a.class_code > b.class_code) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((role) => {
                    let rate = new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    });
                    return (
                      <tr key={role.id}>
                        {selectedID !== role.id ? (
                          <>
                            <td>{role.role_name}</td>
                            <td>{role.class_code}</td>
                            <td>{rate.format(role.rate)}</td>
                            <td>
                              <Button
                                value={"Edit"}
                                type={"button"}
                                onclick={() => {
                                  setSelectedID(role.id);
                                  setSelectedEditedID({
                                    role_name: role.role_name,
                                    class_code: role.class_code,
                                    rate: role.rate,
                                  });
                                }}
                              />
                              <Button
                                value={"Delete"}
                                type={"button"}
                                onclick={() => {
                                  handleDelete(role.id);
                                }}
                              />
                            </td>
                          </>
                        ) : (
                          <>
                            <td>
                              <input
                                type={"text"}
                                value={selectedEditedID.role_name}
                                onChange={(e) => {
                                  setSelectedEditedID({
                                    ...selectedEditedID,
                                    role_name: e.target.value,
                                  });
                                }}
                                name={"roleNameValue"}
                                placeholder={"Enter Role Name"}
                              />
                            </td>
                            <td>
                              <input
                                type={"text"}
                                value={selectedEditedID.class_code}
                                onChange={(e) => {
                                  setSelectedEditedID({
                                    ...selectedEditedID,
                                    class_code: e.target.value,
                                  });
                                }}
                                name={"classCodeValue"}
                                placeholder={"Enter Classification Code"}
                              />
                            </td>
                            <td>
                              <input
                                type={"number"}
                                value={selectedEditedID.rate}
                                onChange={(e) => {
                                  setSelectedEditedID({
                                    ...selectedEditedID,
                                    rate: e.target.value,
                                  });
                                }}
                                name={"rateValue"}
                                placeholder={"Enter Rate"}
                              />
                            </td>
                            <td>
                              <Button
                                value={"Save"}
                                type={"button"}
                                onclick={() => {
                                  handleEdit(role.id);
                                }}
                              />
                              <Button
                                value={"Cancel"}
                                type={"button"}
                                onclick={() => {
                                  setSelectedID(null);
                                  setSelectedEditedID({
                                    role_name: "",
                                    class_code: "",
                                    rate: 0,
                                  });
                                }}
                              />
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="project-page__sub-container">
            <Button
              value={"Add Role"}
              type={"button"}
              onclick={() => {
                setShowAddRoleModal(true);
              }}
            />
          </div>
          {showAddRoleModal && (
            <PopupRoleAdd
              userID={user.id}
              cancelText={"Cancel"}
              confirmText={"Add"}
              setState={setShowAddRoleModal}
              initialState={showAddRoleModal}
              headerText={"Add a Role"}
              data={[{}]}
            />
          )}
        </div>
      </main>
    );
  }
}

export default Role;
