import "./Users.scss";
import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getProfile, getUsers } from "../../utils/apiUtils.mjs";

function Users() {
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState(null);
  const [failedAuth, setFailedAuth] = useState(false);
  const UserID = useParams();
  const Navigate = useNavigate();

  useEffect(() => {
    const getProfileFunction = async () => {
      const token = sessionStorage.getItem("token");

      if (!token) {
        return setFailedAuth(true);
      }

      try {
        // Get the data from the API
        const { data } = await getProfile(token);
        setUser(data);
      } catch (error) {
        setFailedAuth(true);
      }
    };
    const getUsersFunction = async () => {
      const token = sessionStorage.getItem("token");

      if (!token) {
        return setFailedAuth(true);
      }

      try {
        // Get the data from the API
        const { data } = await getUsers(token);
        setUsers(data);
      } catch (error) {
        setFailedAuth(true);
      }
    };
    getProfileFunction();
    getUsersFunction();
  }, []);

  if (failedAuth) {
    return (
      <main className="users-page">
        <p>You must be logged in to see this page.</p>
        <p>
          <Link to="/Login">Log in</Link>
        </p>
      </main>
    );
  }

  if (!user && !users) {
    return (
      <main className="users-page">
        <p>Loading...</p>
      </main>
    );
  }

  return (
    <main className="users-page">
      {users !== null &&
        users
          .sort(function (a, b) {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
          .map((user) => {
            return (
              <div className="users-page__sub-section">
                <h2 className="users-page__sub-header">
                  {user.first_name} {user.last_name}
                </h2>
                <div className="users-page__sub-container">
                  <p>Email: {user.email}</p>
                  <p>Phone: {user.phone}</p>
                  <p>Address: {user.address}</p>
                  <p>Classification Code: {user.class_code}</p>
                  <p>Role Access: {user.role_access}</p>
                  <p>Profile URL: {user.profile_url}</p>
                </div>
              </div>
            );
          })}
    </main>
  );
}

export default Users;
