import "./PopupRoleAdd.scss";
import React, { useState } from "react";
import Textbox from "../Textbox/Textbox";
import Button from "../Buttons/Buttons";
import { postRoleList } from "../../utils/apiUtils.mjs";

const PopupRoleAdd = ({
  userID,
  cancelText,
  confirmText,
  setState,
  initialState,
  headerText,
  subHeaderText,
  data,
}) => {
  const [addRole, setAddRole] = useState({
    role_name: "",
    class_code: "",
    rate: 0,
  });
  const [isRoleNameValid, setIsRoleNameValid] = useState(false);
  const [isClassCodeValid, setIsClassCodeValid] = useState(false);
  const [isRateValid, setIsRateValid] = useState(false);

  const handleAddProject = (event) => {
    if (addRole.role_name === "") {
      setIsRoleNameValid(true);
      event.preventDefault();
    }
    if (addRole.class_code === "") {
      setIsClassCodeValid(true);
      event.preventDefault();
    }
    if (addRole.rate === "") {
      setIsRateValid(true);
      event.preventDefault();
    }
    if (
      addRole.role_name !== "" &&
      addRole.class_code !== "" &&
      addRole.rate !== ""
    ) {
      postRoleListFunction(
        addRole.role_name,
        addRole.class_code,
        Number(addRole.rate)
      );
      event.preventDefault();
    }
  };

  const postRoleListFunction = async (role_name, class_code, rate) => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      setState(false);
    }

    try {
      // Get the data from the API
      const { data } = await postRoleList(
        token,

        role_name,
        class_code,
        rate
      );
      setState(false);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  return (
    <div className="popup">
      <div className="popup__inner">
        <div className="popup__content">
          <h1 className="popup__header">{headerText}</h1>
          <form onSubmit={handleAddProject}>
            <div>
              <Textbox
                label={"Role Name"}
                type={"text"}
                name={"roleValue"}
                placeholder={"Enter Role Name"}
                onChange={(e) => {
                  setAddRole({
                    ...addRole,
                    role_name: e.target.value,
                  });
                  setIsRoleNameValid(false);
                }}
                value={addRole.role_name}
              />
              {isRoleNameValid && (
                <label className="popup__sub-header--modifier">
                  Please enter valid role name
                </label>
              )}
              <Textbox
                label={"Classification Code"}
                type={"text"}
                name={"classCodeValue"}
                placeholder={"Enter Classification Code"}
                onChange={(e) => {
                  setAddRole({
                    ...addRole,
                    class_code: e.target.value,
                  });
                  setIsClassCodeValid(false);
                }}
                value={addRole.class_code}
              />
              {isClassCodeValid && (
                <label className="popup__sub-header--modifier">
                  Please enter valid classification code
                </label>
              )}
              <Textbox
                label={"Chargeout Rate"}
                type={"number"}
                name={"rateValue"}
                placeholder={"Enter Rate"}
                onChange={(e) => {
                  setAddRole({
                    ...addRole,
                    rate: e.target.value,
                  });
                  setIsClassCodeValid(false);
                }}
                value={addRole.rate}
              />
              {isRateValid && (
                <label className="popup__sub-header--modifier">
                  Please enter valid rate
                </label>
              )}
            </div>
            <div className="popup__button-container">
              <Button
                id={""}
                icon={""}
                alt={""}
                value={cancelText}
                onclick={() => {
                  setState(false);
                }}
                type={"button"}
                className={""}
              />
              <Button
                id={""}
                icon={""}
                alt={""}
                value={confirmText}
                type={"submit"}
                className={""}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PopupRoleAdd;
