import React from "react";
import "./TableProject.scss";
import { useState } from "react";
import { Fragment } from "react";
import TableHeaderProject from "../TableHeaderProject/TableHeaderProject";
import TableRowProject from "../TableRowProject/TableRowProject";
import TableEditableRowProject from "../../components/TableEditableRowProject/TableEditableRowProject";

const TableProject = ({
  theadData,
  tbodyData,
  sorting,
  sortTable,
  customClass,
  user,
  users,
  projectList,
  timesheetList,
  rolesList,
  editRowID,
  editFormData,
  handleEditClick,
  handleEditFormChange,
  handleEditFormSubmit,
  handleCancelClick,
  handleDeleteClick,
}) => {
  const [showProjectLogModal, setShowProjectLogModal] = useState(false);

  return (
    <form onSubmit={handleEditFormSubmit}>
      <div className="scrollit">
        <table className={`TableProject__table`}>
          <thead className={`TableProject__thead`}>
            <tr key={"header"} className={`TableProject__tr`}>
              {theadData.map((h) => {
                // Maps all table header columns
                return (
                  <TableHeaderProject
                    key={h}
                    item={h}
                    sorting={sorting}
                    sortTable={sortTable}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody className={`TableProject__tbody`}>
            {tbodyData.map((item) => {
              // Maps all row items
              return (
                <Fragment>
                  {editRowID === item.id ? (
                    <TableEditableRowProject
                      editFormData={editFormData}
                      handleEditFormChange={handleEditFormChange}
                      handleCancelClick={handleCancelClick}
                      data={item}
                      user={user}
                      projectList={projectList}
                    />
                  ) : (
                    <TableRowProject
                      id={item.id}
                      data={item}
                      user={user}
                      users={users}
                      projectList={projectList}
                      handleEditClick={handleEditClick}
                      handleDeleteClick={handleDeleteClick}
                      timesheetList={timesheetList}
                      rolesList={rolesList}
                    />
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </form>
  );
};

export default TableProject;
