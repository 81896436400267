import React from "react";
import "./TableHeaderProject.scss";

const TableHeaderProject = ({ item, sorting, sortTable }) => {
  const isDescSorting = sorting.item === item && sorting.order === "desc";
  const isAscSorting = sorting.item === item && sorting.order === "asc";
  const futureSortingOrder = isDescSorting ? "asc" : "desc";

  return (
    <td
      key={item}
      title={item}
      className={`users-table-cell TableProject__td`}
      onClick={() => sortTable({ item, order: futureSortingOrder })}
    >
      {item}
      {isDescSorting && <span>down</span>}
      {isAscSorting && <span>up</span>}
    </td>
  );
};

export default TableHeaderProject;
