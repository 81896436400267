import axios from "axios";

const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL;

const postLogin = async (email, password) => {
  return axios.post(`${API_URL}/users/login`, {
    email,
    password,
  });
};

const postSignup = async (
  email,
  password,
  first_name,
  last_name,
  phone,
  address
) => {
  return axios.post(`${API_URL}/users/register`, {
    email,
    password,
    first_name,
    last_name,
    phone,
    address,
  });
};

const getProfile = async (token) => {
  return axios.get(`${API_URL}/users/profile`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getUsers = async (token) => {
  return axios.get(`${API_URL}/users/users`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getClientList = async (token) => {
  return axios.get(`${API_URL}/clients/list`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getProjectList = async (token) => {
  return axios.get(`${API_URL}/projects/list`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const postProjectAdd = async (
  token,
  user_id,
  project_name,
  project_budget,
  project_labour_effort,
  project_revenue
) => {
  return axios.post(`${API_URL}/projects/add`, {
    user_id,
    project_name,
    project_budget,
    project_labour_effort,
    project_revenue,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteProjectList = async (token, ProjectID) => {
  return axios.delete(`${API_URL}/projects/delete/${ProjectID}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const putProjectEdit = async (
  token,
  id,
  project_name,
  project_budget,
  project_labour_effort,
  project_revenue,
  user_id,
  client_id
) => {
  return axios.put(`${API_URL}/projects/edit/${id}`, {
    id,
    project_name,
    project_budget,
    project_labour_effort,
    project_revenue,
    user_id,
    client_id,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const putProjectUpdate = async (token, ProjectID) => {
  return axios.put(`${API_URL}/projects/update/${ProjectID}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getActiveTaskList = async (token) => {
  return axios.get(`${API_URL}/tasks/list`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const postActiveTaskList = async (
  token,
  user_id,
  project_id,
  start,
  task_description
) => {
  return axios.post(`${API_URL}/tasks/add`, {
    user_id,
    project_id,
    start,
    task_description,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteActiveTaskList = async (token, TaskID) => {
  return axios.delete(`${API_URL}/tasks/delete/${TaskID}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const editActiveTaskList = async (
  token,
  TaskID,
  user_id,
  project_id,
  task_description,
  start,
  end
) => {
  return axios.put(`${API_URL}/tasks/edit/${TaskID}`, {
    user_id,
    project_id,
    task_description,
    start,
    end,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getTimesheetList = async (token) => {
  return axios.get(`${API_URL}/timesheets/list`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const postTimesheetList = async (
  token,
  user_id,
  project_id,
  ending_week_date,
  mon_hours,
  mon_description,
  tue_hours,
  tue_description,
  wed_hours,
  wed_description,
  thur_hours,
  thur_description,
  fri_hours,
  fri_description,
  sat_hours,
  sat_description,
  sun_hours,
  sun_description
) => {
  return axios.post(`${API_URL}/timesheets/add`, {
    user_id,
    project_id,
    ending_week_date,
    mon_hours,
    mon_description,
    tue_hours,
    tue_description,
    wed_hours,
    wed_description,
    thur_hours,
    thur_description,
    fri_hours,
    fri_description,
    sat_hours,
    sat_description,
    sun_hours,
    sun_description,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteTimesheetList = async (token, TimesheetID) => {
  return axios.delete(`${API_URL}/timesheets/delete/${TimesheetID}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const editTimesheetList = async (
  token,
  TimesheetID,
  user_id,
  project_id,
  ending_week_date,
  mon_hours,
  mon_description,
  tue_hours,
  tue_description,
  wed_hours,
  wed_description,
  thur_hours,
  thur_description,
  fri_hours,
  fri_description,
  sat_hours,
  sat_description,
  sun_hours,
  sun_description
) => {
  return axios.put(`${API_URL}/timesheets/edit/${TimesheetID}`, {
    user_id,
    project_id,
    ending_week_date,
    mon_hours,
    mon_description,
    tue_hours,
    tue_description,
    wed_hours,
    wed_description,
    thur_hours,
    thur_description,
    fri_hours,
    fri_description,
    sat_hours,
    sat_description,
    sun_hours,
    sun_description,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getTimesheetCalendarList = async (token) => {
  return axios.get(`${API_URL}/timesheetCalendars/list`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const postTimesheetCalendarList = async (
  token,
  user_id,
  project_id,
  date,
  description,
  hours
) => {
  return axios.post(`${API_URL}/timesheetCalendars/add`, {
    user_id,
    project_id,
    date,
    description,
    hours,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteTimesheetCalendarList = async (token, TimesheetID) => {
  return axios.delete(`${API_URL}/timesheetCalendars/delete/${TimesheetID}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const editTimesheetCalendarList = async (
  token,
  TimesheetID,
  user_id,
  project_id,
  date,
  description,
  hours
) => {
  return axios.put(`${API_URL}/timesheetCalendars/edit/${TimesheetID}`, {
    user_id,
    project_id,
    date,
    description,
    hours,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getRoles = async (token) => {
  return axios.get(`${API_URL}/roles/list`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const postRoleList = async (token, role_name, class_code, rate) => {
  return axios.post(`${API_URL}/roles/add`, {
    role_name,
    class_code,
    rate,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteRolesList = async (token, RoleID) => {
  return axios.delete(`${API_URL}/roles/delete/${RoleID}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const editRolesList = async (token, RoleID, role_name, class_code, rate) => {
  return axios.put(`${API_URL}/roles/edit/${RoleID}`, {
    role_name,
    class_code,
    rate,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export {
  postLogin,
  postSignup,
  getProfile,
  getUsers,
  getClientList,
  getProjectList,
  postProjectAdd,
  putProjectUpdate,
  deleteProjectList,
  putProjectEdit,
  getActiveTaskList,
  postActiveTaskList,
  deleteActiveTaskList,
  editActiveTaskList,
  getTimesheetList,
  postTimesheetList,
  deleteTimesheetList,
  editTimesheetList,
  getTimesheetCalendarList,
  postTimesheetCalendarList,
  deleteTimesheetCalendarList,
  editTimesheetCalendarList,
  getRoles,
  postRoleList,
  deleteRolesList,
  editRolesList,
};
