import "./Profile.scss";
import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getProfile } from "../../utils/apiUtils.mjs";

function Profile() {
  const [user, setUser] = useState(null);
  const [failedAuth, setFailedAuth] = useState(false);
  const UserID = useParams();
  const Navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      const token = sessionStorage.getItem("token");

      if (!token) {
        return setFailedAuth(true);
      }

      try {
        // Get the data from the API
        const { data } = await getProfile(token);
        setUser(data);
      } catch (error) {
        console.log(error.response.data);
        setFailedAuth(true);
      }
    };
    loadData();
  }, []);

  if (failedAuth) {
    return (
      <main className="profile-page">
        <p>You must be logged in to see this page.</p>
        <p>
          <Link to="/Login">Log in</Link>
        </p>
      </main>
    );
  }

  if (!user) {
    return (
      <main className="profile-page">
        <p>Loading...</p>
      </main>
    );
  }

  return (
    <main className="profile-page">
      <div className="profile-page__sub-section">
        <h2 className="project-page__sub-header">My Profile</h2>
        <div className="profile-page__sub-container">
          <p>
            Name: {user.first_name} {user.last_name}
          </p>
          <p>Email: {user.email}</p>
          <p>Phone: {user.phone}</p>
          <p>Address: {user.address}</p>
          <p>Classification Code: {user.class_code}</p>
          <p>Role Access: {user.role_access}</p>
          <p>Profile URL: {user.profile_url}</p>
        </div>
      </div>
    </main>
  );
}

export default Profile;
