import React from "react";
import "./Chart.scss";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const Chart = ({ userProject }) => {
  const projectData = () => {
    let metrics = [];
    let totalbudget = 0;
    let totalLabourEffort = 0;
    let totalRevenue = 0;
    for (let i = 0; i < userProject.length; i++) {
      totalbudget += userProject[i].project_budget;
      totalLabourEffort += userProject[i].project_labour_effort;
      totalRevenue += userProject[i].project_revenue;
    }
    metrics.push(totalbudget, totalLabourEffort, totalRevenue);
    return metrics;
  };

  const data = {
    labels: ["Total Budget", "Total Labour Effort", "Total Revenue"],
    datasets: [
      {
        label: "Project Metrics",
        data: projectData(),
        backgroundColor: "#1abc9c",
        borderColor: "black",
        borderWidth: 1,
      },
    ],
  };
  const options = {};
  return (
    <div className="Chart">
      <Bar data={data} options={options}></Bar>
    </div>
  );
};

export default Chart;
