import "./PopupProjectTimesheetLog.scss";
import React from "react";
import Textbox from "../Textbox/Textbox";
import Button from "../Buttons/Buttons";

const PopupProjectTimesheetLog = ({
  cancelText,
  setTrigger,
  findProject,
  findUser,
  projectName,
  selectedProject,
  data,
  rolesList,
  users,
}) => {
  let rate = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const findClassCode = (userID) => {
    for (let i = 0; i < users.length; i++) {
      if (userID === users[i].id) {
        return users[i].class_code;
      }
    }
    return "";
  };

  const findRate = (userID) => {
    let classCode = findClassCode(userID);
    for (let j = 0; j < rolesList.length; j++) {
      if (classCode === rolesList[j].class_code) {
        return rolesList[j].rate;
      }
    }
    return 0;
  };

  return (
    <div className="popup">
      <div className="popup__inner">
        <div className="popup__content">
          <h1 className="popup__header">{projectName}</h1>
          <div className="popup__table">
            <table>
              <thead>
                <td>Name</td>
                <td>Hours</td>
                <td>Description</td>
                <td>Date</td>
                <td>Class Code</td>
                <td>Rate</td>
                <td>Cost</td>
              </thead>
              <tbody>
                {selectedProject
                  .sort(function (a, b) {
                    if (a.date < b.date) {
                      return -1;
                    }
                    if (a.date > b.date) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((timesheet) => {
                    return (
                      <tr>
                        <td>{findUser(timesheet.user_id)}</td>
                        <td>{timesheet.hours}</td>
                        <td>{timesheet.description}</td>
                        <td>{timesheet.date}</td>
                        <td>{findClassCode(timesheet.user_id)}</td>
                        <td>{rate.format(findRate(timesheet.user_id))}</td>
                        <td>
                          {rate.format(
                            timesheet.hours * findRate(timesheet.user_id)
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="popup__button-container">
            <Button
              id={""}
              icon={""}
              alt={""}
              value={cancelText}
              onclick={() => {
                setTrigger(false);
              }}
              type={"button"}
              className={""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupProjectTimesheetLog;
