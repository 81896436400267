import React from "react";
import "./TableEditableRowProject.scss";
import Button from "../Buttons/Buttons";

const TableEditableRowProject = ({
  editFormData,
  handleEditFormChange,
  handleCancelClick,
  user,
  projectList,
  timesheetList,
}) => {
  let rate = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return (
    <tr className={`TableProject__tr`}>
      <td className={`TableProject__td`}>
        {user.first_name} {user.last_name}
      </td>
      <td className={`TableProject__td`}>
        <input
          type="text"
          name="project_name"
          placeholder="Enter a project name"
          value={editFormData.project_name}
          onChange={handleEditFormChange}
        />
      </td>
      <td className={`TableProject__td`}>
        <input
          type="text"
          name="project_budget"
          placeholder="Enter hours"
          value={editFormData.project_budget}
          onChange={handleEditFormChange}
        />
      </td>
      <td className={`TableProject__td`}>
        {rate.format(editFormData.project_labour_effort)}
      </td>
      <td className={`TableProject__td`}>
        {rate.format(editFormData.project_revenue)}
      </td>

      <td className={`TableProject__td`}>
        <Button
          id={""}
          icon={""}
          alt={""}
          value={"Save"}
          onclick={() => {}}
          type={"submit"}
          className={""}
        />
        <Button
          id={""}
          icon={""}
          alt={""}
          value={"Cancel"}
          onclick={handleCancelClick}
          type={"button"}
          className={""}
        />
      </td>
    </tr>
  );
};

export default TableEditableRowProject;
