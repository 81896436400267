import React from "react";
import "./TimesheetsPage.scss";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TableTimesheet from "../../components/TableTimesheet/TableTimesheet";
import dates from "../../components/Data/dates.json";
import PopupAddProjectTimesheet from "../../components/PopupAddProjectTimesheet/PopupAddProjectTimesheet";
import Button from "../../components/Buttons/Buttons";
import UnderConstruction from "../../assets/images/Under Construction.jpg";
import {
  getProfile,
  getProjectList,
  putProjectUpdate,
  getTimesheetList,
  postTimesheetList,
  deleteTimesheetList,
  editTimesheetList,
  getTimesheetCalendarList,
} from "../../utils/apiUtils.mjs";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import PopupTimesheetAddCalendar from "../../components/PopupTimesheetAddCalendar/PopupTimesheetAddCalendar";
import PopupTimesheetEditCalendar from "../../components/PopupTimesheetEditCalendar/PopupTimesheetEditCalendar";

const TimesheetsPage = () => {
  /* State Variables */
  const [sorting, setSorting] = useState({ item: "id", order: "asc" });
  const [tbodyData, setTbodyData] = useState(null);
  const [addFormData, setAddFormData] = useState({
    id: "",
    user_id: "",
    project_id: "",
    ending_week_date: "",
    mon_hours: 0,
    mon_description: "",
    tue_hours: 0,
    tue_description: "",
    wed_hours: 0,
    wed_description: "",
    thur_hours: 0,
    thur_description: "",
    fri_hours: 0,
    fri_description: "",
    sat_hours: 0,
    sat_description: "",
    sun_hours: 0,
    sun_description: "",
  });
  const [editFormData, setEditFormData] = useState({
    id: "",
    user_id: "",
    project_id: "",
    ending_week_date: "",
    mon_hours: 0,
    mon_description: "",
    tue_hours: 0,
    tue_description: "",
    wed_hours: 0,
    wed_description: "",
    thur_hours: 0,
    thur_description: "",
    fri_hours: 0,
    fri_description: "",
    sat_hours: 0,
    sat_description: "",
    sun_hours: 0,
    sun_description: "",
  });
  const [editRowID, setEditRowID] = useState(null);
  const [user, setUser] = useState(null);
  const [projectList, setProjectList] = useState(null);
  const [timesheetList, setTimesheetList] = useState(null);
  const [timesheetCalendarList, setTimesheetCalendarList] = useState(null);
  const [failedAuth, setFailedAuth] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dates[0].date);
  const [Dates, setDates] = useState(dates);
  const [isMonValid, setIsMonValid] = useState(false);
  const [isTueValid, setIsTueValid] = useState(false);
  const [isWedValid, setIsWedValid] = useState(false);
  const [isThurValid, setIsThurValid] = useState(false);
  const [isFriValid, setIsFriValid] = useState(false);
  const [isSatValid, setIsSatValid] = useState(false);
  const [isSunValid, setIsSunValid] = useState(false);
  const [showCalendarAddModal, setShowCalendarAddModal] = useState(false);
  const [showCalendarEditModal, setShowCalendarEditModal] = useState(false);
  const [selectedAddDate, setSelectedAddDate] = useState(null);
  const [selectedEditDate, setSelectedEditDate] = useState(null);
  const Navigate = useNavigate();

  /* Variables */
  const theadData = [
    "Project Name",
    "Monday Hours",
    "Monday Description",
    "Tuesday Hours",
    "Tuesday Description",
    "Wednesday Hours",
    "Wednesday Description",
    "Thursday Hours",
    "Thursday Description",
    "Friday Hours",
    "Friday Description",
    "Saturday Hours",
    "Saturday Description",
    "Sunday Hours",
    "Sunday Description",
    "Actions",
  ];

  /* Functions */
  const sortTable = (newSorting) => {
    // Sorts table in asscending or descending order
    setSorting(newSorting);
  };

  const getProfileFunction = async () => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      return setFailedAuth(true);
    }

    try {
      // Get the data from the API
      const { data } = await getProfile(token);
      setUser(data);
    } catch (error) {}
  };

  const getTimesheetListFunction = async () => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      return setFailedAuth(true);
    }

    try {
      // Get the data from the API
      const { data } = await getTimesheetList(token);
      setTimesheetList(data);
      setTbodyData(data);
    } catch (error) {}
  };

  const getTimesheetCalendarListFunction = async () => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      return setFailedAuth(true);
    }

    try {
      // Get the data from the API
      const { data } = await getTimesheetCalendarList(token);
      setTimesheetCalendarList(data);
    } catch (error) {}
  };

  const getProjectListFunction = async () => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      return setFailedAuth(true);
    }

    try {
      // Get the data from the API
      const { data } = await getProjectList(token);
      setProjectList(data);
    } catch (error) {}
  };

  const postTimesheetListFunction = async ({
    user_id,
    project_id,
    ending_week_date,
    mon_hours,
    mon_description,
    tue_hours,
    tue_description,
    wed_hours,
    wed_description,
    thur_hours,
    thur_description,
    fri_hours,
    fri_description,
    sat_hours,
    sat_description,
    sun_hours,
    sun_description,
  }) => {
    const token = sessionStorage.getItem("token");

    user_id = user.id;
    if (!token) {
      return setFailedAuth(true);
    }
    try {
      // Get the data from the API
      await postTimesheetList(
        token,
        user_id,
        project_id,
        ending_week_date,
        mon_hours,
        mon_description,
        tue_hours,
        tue_description,
        wed_hours,
        wed_description,
        thur_hours,
        thur_description,
        fri_hours,
        fri_description,
        sat_hours,
        sat_description,
        sun_hours,
        sun_description
      );
      await putProjectUpdateFunction(project_id);
      await getTimesheetListFunction();
      setShowModal(false);
    } catch (error) {}
  };

  const putProjectUpdateFunction = async (ProjectID) => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      return setFailedAuth(true);
    }
    try {
      await putProjectUpdate(token, ProjectID);
    } catch (error) {}
  };

  const deleteTimesheetListFunction = async (TimesheetID, ProjectID) => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      return setFailedAuth(true);
    }
    try {
      const { data } = await deleteTimesheetList(token, TimesheetID);
      await putProjectUpdateFunction(ProjectID);
      await getTimesheetListFunction();
      return data;
    } catch (error) {}
  };

  const editTimesheetListFunction = async ({
    id,
    user_id,
    project_id,
    ending_week_date,
    mon_hours,
    mon_description,
    tue_hours,
    tue_description,
    wed_hours,
    wed_description,
    thur_hours,
    thur_description,
    fri_hours,
    fri_description,
    sat_hours,
    sat_description,
    sun_hours,
    sun_description,
  }) => {
    const token = sessionStorage.getItem("token");

    user_id = user.id;
    if (!token) {
      return setFailedAuth(true);
    }
    try {
      // Get the data from the API

      await editTimesheetList(
        token,
        id,
        user_id,
        project_id,
        ending_week_date,
        mon_hours,
        mon_description,
        tue_hours,
        tue_description,
        wed_hours,
        wed_description,
        thur_hours,
        thur_description,
        fri_hours,
        fri_description,
        sat_hours,
        sat_description,
        sun_hours,
        sun_description
      );
      await putProjectUpdateFunction(project_id);
      await getTimesheetListFunction();
    } catch (error) {}
  };

  /* Validations */
  const isMondayHoursValid = () => {
    // Monday Hours Validation
    if (isNaN(addFormData.mon_hours)) {
      return false;
    }
    return true;
  };

  const isTuesdayHoursValid = () => {
    // Tuesday Hours Validation
    if (isNaN(addFormData.tue_hours)) {
      return false;
    }
    return true;
  };

  const isWednesdayHoursValid = () => {
    // Wednesday Hours Validation
    if (isNaN(addFormData.wed_hours)) {
      return false;
    }
    return true;
  };

  const isThursdayHoursValid = () => {
    // Thursday Hours Validation
    if (isNaN(addFormData.thur_hours)) {
      return false;
    }
    return true;
  };

  const isFridayHoursValid = () => {
    // Friday Hours Validation
    if (isNaN(addFormData.fri_hours)) {
      return false;
    }
    return true;
  };

  const isSaturdayHoursValid = () => {
    // Saturday Hours Validation
    if (isNaN(addFormData.sat_hours)) {
      return false;
    }
    return true;
  };

  const isSundayHoursValid = () => {
    // Sunday Hours Validation
    if (isNaN(addFormData.sun_hours)) {
      return false;
    }
    return true;
  };

  const isFormValid = () => {
    // Form validation
    if (
      isMondayHoursValid() &&
      isTuesdayHoursValid() &&
      isWednesdayHoursValid() &&
      isThursdayHoursValid() &&
      isFridayHoursValid() &&
      isSaturdayHoursValid() &&
      isSundayHoursValid()
    ) {
      return true;
    }
    return false;
  };

  /* Handle Events */
  const handleAddFormChange = (event) => {
    // Form submit event handler

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };

  const handleEditFormChange = (event) => {
    // Edit form submit event handler

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };

  const handleDateChange = (event) => {
    const value = event.target.value;
    setSelectedDate(value);
  };

  const handleEditClick = (event, row) => {
    // Edit button event handler
    event.preventDefault();
    setEditRowID(row.id);

    const formValues = {
      id: row.id,
      user_id: row.user_id,
      project_id: row.project_id,
      ending_week_date: row.ending_week_date,
      mon_hours: row.mon_hours,
      mon_description: row.mon_description,
      tue_hours: row.tue_hours,
      tue_description: row.tue_description,
      wed_hours: row.wed_hours,
      wed_description: row.wed_description,
      thur_hours: row.thur_hours,
      thur_description: row.thur_description,
      fri_hours: row.fri_hours,
      fri_description: row.fri_description,
      sat_hours: row.sat_hours,
      sat_description: row.sat_description,
      sun_hours: row.sun_hours,
      sun_description: row.sun_description,
    };
    setEditFormData(formValues);
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();

    if (!isMondayHoursValid()) {
      setIsMonValid(true);
    } else {
      setIsMonValid(false);
    }

    if (!isTuesdayHoursValid()) {
      setIsTueValid(true);
    } else {
      setIsTueValid(false);
    }

    if (!isWednesdayHoursValid()) {
      setIsWedValid(true);
    } else {
      setIsWedValid(false);
    }

    if (!isThursdayHoursValid()) {
      setIsThurValid(true);
    } else {
      setIsThurValid(false);
    }

    if (!isFridayHoursValid()) {
      setIsFriValid(true);
    } else {
      setIsFriValid(false);
    }

    if (!isSaturdayHoursValid()) {
      setIsSatValid(true);
    } else {
      setIsSatValid(false);
    }

    if (!isSundayHoursValid()) {
      setIsSunValid(true);
    } else {
      setIsSunValid(false);
    }

    if (isFormValid()) {
      const selectedProjectID = projectList.find((projects) => {
        return projects.project_name === event.target.add_project.value;
      });
      const newRowID = {
        id: addFormData.id,
        user_id: addFormData.user_id,
        project_id: selectedProjectID.id,
        ending_week_date: selectedDate,
        mon_hours: addFormData.mon_hours,
        mon_description: addFormData.mon_description,
        tue_hours: addFormData.tue_hours,
        tue_description: addFormData.tue_description,
        wed_hours: addFormData.wed_hours,
        wed_description: addFormData.wed_description,
        thur_hours: addFormData.thur_hours,
        thur_description: addFormData.thur_description,
        fri_hours: addFormData.fri_hours,
        fri_description: addFormData.fri_description,
        sat_hours: addFormData.sat_hours,
        sat_description: addFormData.sat_description,
        sun_hours: addFormData.sun_hours,
        sun_description: addFormData.sun_description,
      };

      const newRow = [...tbodyData, newRowID];
      postTimesheetListFunction(newRowID);
      setAddFormData({
        id: "",
        user_id: "",
        project_id: "",
        ending_week_date: "",
        mon_hours: 0,
        mon_description: "",
        tue_hours: 0,
        tue_description: "",
        wed_hours: 0,
        wed_description: "",
        thur_hours: 0,
        thur_description: "",
        fri_hours: 0,
        fri_description: "",
        sat_hours: 0,
        sat_description: "",
        sun_hours: 0,
        sun_description: "",
      });
      // setShowModal(false);
    }
  };

  const handleCancel = () => {
    // Handle Cancel Form resets whole form
    setAddFormData({
      id: "",
      user_id: "",
      project_id: "",
      ending_week_date: "",
      mon_hours: 0,
      mon_description: "",
      tue_hours: 0,
      tue_description: "",
      wed_hours: 0,
      wed_description: "",
      thur_hours: 0,
      thur_description: "",
      fri_hours: 0,
      fri_description: "",
      sat_hours: 0,
      sat_description: "",
      sun_hours: 0,
      sun_description: "",
    });
    setIsMonValid(false);
    setIsTueValid(false);
    setIsWedValid(false);
    setIsThurValid(false);
    setIsFriValid(false);
    setIsSatValid(false);
    setIsSunValid(false);
    setShowModal(false);
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();

    if (
      !isNaN(editFormData.mon_hours) &&
      !isNaN(editFormData.tue_hours) &&
      !isNaN(editFormData.wed_hours) &&
      !isNaN(editFormData.thur_hours) &&
      !isNaN(editFormData.fri_hours) &&
      !isNaN(editFormData.sat_hours) &&
      !isNaN(editFormData.sun_hours)
    ) {
      const editedRow = {
        id: editRowID,
        user_id: editFormData.user_id,
        project_id: editFormData.project_id,
        ending_week_date: editFormData.ending_week_date,
        mon_hours: editFormData.mon_hours,
        mon_description: editFormData.mon_description,
        tue_hours: editFormData.tue_hours,
        tue_description: editFormData.tue_description,
        wed_hours: editFormData.wed_hours,
        wed_description: editFormData.wed_description,
        thur_hours: editFormData.thur_hours,
        thur_description: editFormData.thur_description,
        fri_hours: editFormData.fri_hours,
        fri_description: editFormData.fri_description,
        sat_hours: editFormData.sat_hours,
        sat_description: editFormData.sat_description,
        sun_hours: editFormData.sun_hours,
        sun_description: editFormData.sun_description,
      };
      const newRow = [...tbodyData];

      const index = tbodyData.findIndex((rowID) => {
        return rowID.id === editRowID;
      });
      newRow[index] = editedRow;

      editTimesheetListFunction(editedRow);
    }
    setEditRowID(null);
  };

  const handleCancelClick = () => {
    setEditRowID(null);
  };

  const handleDeleteClick = (row) => {
    const newRow = [...tbodyData];
    const index = tbodyData.findIndex((rowID) => {
      return rowID.id === row.id;
    });
    newRow.splice(index, 1);

    deleteTimesheetListFunction(row.id, row.project_id);
  };

  const handleAddProject = () => {
    setShowModal(true);
  };

  const dateEventHandler = (info, setShowCalendarModal) => {
    setSelectedAddDate(info.dateStr);
    setShowCalendarModal(true);
  };

  const calendarTimesheet = () => {
    const dates = [];

    if (timesheetCalendarList !== null) {
      for (let i = 0; i < timesheetCalendarList.length; i++) {
        if (timesheetCalendarList[i].user_id === user.id) {
          dates.push({
            title: `${findProject(timesheetCalendarList[i].project_id)}\n(${
              timesheetCalendarList[i].hours
            } hours)\n${timesheetCalendarList[i].description}\n${
              timesheetCalendarList[i].project_id
            }`,
            date: `${timesheetCalendarList[i].date}`,
          });
        }
      }
    }
    return dates;
  };

  const findProject = (id) => {
    for (let i = 0; i < projectList.length; i++) {
      if (projectList[i].id === id) {
        return `${projectList[i].project_name}`;
      }
    }
  };

  // a custom render function
  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  }

  useEffect(() => {
    const getProfileFunction = async () => {
      const token = sessionStorage.getItem("token");

      if (!token) {
        return setFailedAuth(true);
      }

      try {
        // Get the data from the API
        const { data } = await getProfile(token);
        setUser(data);
      } catch (error) {}
    };

    const getTimesheetListFunction = async () => {
      const token = sessionStorage.getItem("token");

      if (!token) {
        return setFailedAuth(true);
      }

      try {
        // Get the data from the API
        const { data } = await getTimesheetList(token);
        setTimesheetList(data);
        setTbodyData(data);
      } catch (error) {}
    };

    const getTimesheetCalendarListFunction = async () => {
      const token = sessionStorage.getItem("token");

      if (!token) {
        return setFailedAuth(true);
      }

      try {
        // Get the data from the API
        const { data } = await getTimesheetCalendarList(token);
        setTimesheetCalendarList(data);
      } catch (error) {}
    };

    const getProjectListFunction = async () => {
      const token = sessionStorage.getItem("token");

      if (!token) {
        return setFailedAuth(true);
      }

      try {
        // Get the data from the API
        const { data } = await getProjectList(token);
        setProjectList(data);
      } catch (error) {}
    };
    getProjectListFunction();
    getProfileFunction();
    getTimesheetListFunction();
    getTimesheetCalendarListFunction();
  }, [showCalendarAddModal, showCalendarEditModal]);

  // console.log(timesheetCalendarList);
  // console.log(user);
  // console.log(projectList);
  // console.log(timesheetList);

  if (failedAuth) {
    return (
      <main className="timesheet-page">
        <p>You must be logged in to see this page.</p>
        <p>
          <Link to="/Login">Log in</Link>
        </p>
      </main>
    );
  }

  if (!user) {
    return (
      <main className="timesheet-page">
        <p>Loading...</p>
      </main>
    );
  }

  if (user && timesheetList && projectList) {
    // Write a filter function for UserID && work_week_ending dispaly to send to tbodyData
    const filteredUserTimesheet = timesheetList.filter((timesheet) => {
      return timesheet.user_id === user.id;
    });
    const filteredTimesheet = filteredUserTimesheet.filter((timesheet) => {
      return timesheet.ending_week_date == selectedDate;
    });
    return (
      <main className="timesheet-page">
        {/* <div className="timesheet-page__sub-section">
          <div className="timesheet-page__sub-container">
            <h2 className="timesheet-page__sub-header">
              Select an end of week date
            </h2>
            <select name="work_week" id="work_week" onChange={handleDateChange}>
              {dates.map((date) => (
                <option key={date.date} value={date.date}>
                  {date.date}
                </option>
              ))}
            </select>
          </div>
          <TableTimesheet
            theadData={theadData}
            tbodyData={filteredTimesheet} //tbodyData timesheetList
            sorting={sorting}
            sortTable={sortTable}
            customClass="table"
            user={user}
            projectList={projectList}
            timesheetList={timesheetList}
            editRowID={editRowID}
            editFormData={editFormData}
            handleEditClick={handleEditClick}
            handleEditFormChange={handleEditFormChange}
            handleEditFormSubmit={handleEditFormSubmit}
            handleCancelClick={handleCancelClick}
            handleDeleteClick={handleDeleteClick}
          />
          {showModal && (
            <PopupAddProjectTimesheet
              confirmText="Add to timesheet"
              cancelText="Cancel"
              addFormData={addFormData}
              projectList={projectList}
              handleAddFormSubmit={handleAddFormSubmit}
              handleAddFormChange={handleAddFormChange}
              setTrigger={setShowModal}
              Dates={Dates}
              handleCancel={handleCancel}
              isMonValid={isMonValid}
              isTueValid={isTueValid}
              isWedValid={isWedValid}
              isThurValid={isThurValid}
              isFriValid={isFriValid}
              isSatValid={isSatValid}
              isSunValid={isSunValid}
            />
          )}
          <div className="timesheet-page__sub-container">
            <Button
              id={""}
              icon={""}
              alt={""}
              value={"Add Project"}
              onclick={handleAddProject}
              type={"button"}
              className={""}
            />
          </div>
        </div> */}
        <div className="timesheet-page__sub-section-calendar">
          <div className="timesheet-page__sub-container-calendar">
            <h2 className="timesheet-page__sub-header">Timesheet</h2>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView={"dayGridWeek"}
              handleWindowResize={true}
              expandRows={true}
              weekends={true}
              selectable={true}
              dateClick={(info) => {
                // console.log("Clicked on: " + info.dateStr);
                // console.log(
                //   "Coordinates: " +
                //     info.jsEvent.pageX +
                //     "," +
                //     info.jsEvent.pageY
                // );
                // change the day's background color just for fun
                // info.dayEl.style.backgroundColor = "red";
                // console.log("Current view: " + info.view.type);
                dateEventHandler(info, setShowCalendarAddModal);
              }}
              className="Calendar"
              events={calendarTimesheet()}
              eventContent={renderEventContent}
              eventClick={(info) => {
                // change the border color just for fun
                // info.el.style.borderColor = "red";

                let project_name = "";
                let hours;
                let description = "";
                let project_id;
                let pos = 0;

                for (; pos < info.event.title.length; pos++) {
                  if (info.event.title[pos] === "\n") {
                    pos++;
                    break;
                  }
                  project_name += info.event.title[pos];
                }

                for (; pos < info.event.title.length; pos++) {
                  if (info.event.title[pos] === "\n") {
                    pos++;
                    break;
                  }
                  hours += info.event.title[pos];
                }

                hours = hours.slice(10, hours.length - 7);
                hours = Number(hours);

                for (; pos < info.event.title.length; pos++) {
                  if (info.event.title[pos] === "\n") {
                    pos++;
                    break;
                  }
                  description += info.event.title[pos];
                }

                for (; pos < info.event.title.length; pos++) {
                  if (info.event.title[pos] === "\n") {
                    pos++;
                    break;
                  }
                  project_id += info.event.title[pos];
                }

                project_id = project_id.slice(9, project_id.length);
                project_id = Number(project_id);

                setSelectedEditDate({
                  date: info.event.startStr,
                  project_name,
                  hours,
                  description,
                  project_id,
                });
                setShowCalendarEditModal(true);
              }}
            />
            {showCalendarAddModal && (
              <PopupTimesheetAddCalendar
                userID={user.id}
                cancelText="Cancel"
                confirmText="Save"
                setState={setShowCalendarAddModal}
                initialState={showCalendarAddModal}
                selectedDate={selectedAddDate}
                headerText="Add a Project to Timesheet"
                subHeaderText={["Selected Date"]}
                data={[projectList]}
              />
            )}
            {showCalendarEditModal && (
              <PopupTimesheetEditCalendar
                userID={user.id}
                cancelText="Cancel"
                confirmText="Save"
                setState={setShowCalendarEditModal}
                initialState={showCalendarEditModal}
                selectedDate={selectedEditDate}
                headerText="Edit a Project to Timesheet"
                subHeaderText={[
                  "Selected Date",
                  "Project Name",
                  "Hours",
                  "Task Description",
                ]}
                data={[projectList, timesheetCalendarList]}
              />
            )}
          </div>
        </div>
        <div className="timesheet-page__sub-section">
          <div className="timesheet-page__sub-container">
            <h2 className="timesheet-page__sub-header">
              Project Manager Time Sheet Review/Approval
            </h2>
          </div>
          <div className="timesheet-page__sub-container">
            <img
              src={UnderConstruction}
              alt="Under Construction"
              className="timesheet-page__img"
            />
          </div>
        </div>
      </main>
    );
  }
};

export default TimesheetsPage;
