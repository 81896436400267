import "./Header.scss";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import Button from "../Buttons/Buttons";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

export default function Header() {
  const location = useLocation();
  const Navigate = useNavigate();

  // const handleLogout = () => {
  //   sessionStorage.removeItem("token");
  //   Navigate("/Login");
  // };

  // const handleLogin = () => {
  //   Navigate("/Login");
  // };

  return (
    <header>
      {/* <aside class="sidebar">
        <div id="leftside-navigation" class="nano">
          <ul class="nano-content">
            <li>
              <Link to={`/Profile/:UserID`} className="nav__link">
                <i class="fa fa-dashboard"></i>
                Profile
              </Link>
            </li>
            <li class="sub-menu">
              <Link to={`/TimesheetsPage/:UserID`} className="nav__link">
                <i class="fa fa-table"></i>
                Timesheets
              </Link>
            </li>
            <li class="sub-menu">
              <Link to={`/ActiveTasks/:UserID`} className="nav__link">
                <i class="fa fa fa-tasks"></i>
                Active Tasks
              </Link>
            </li>
            <li class="sub-menu">
              <Link to={`/ProjectsPage/:UserID`} className="nav__link">
                <i class="fa fa-bar-chart-o"></i>
                Projects
              </Link>
            </li>
            <li class="sub-menu">
              <Link to={`/`} className="nav__link">
                <i class="fa fa-cogs"></i>
                Home
              </Link>
            </li>
            <li class="sub-menu">
              <Button
                id={""}
                icon={""}
                alt={""}
                value={"Login"}
                onclick={handleLogin}
                type={"button"}
                className={""}
              />
            </li>
            <li class="sub-menu">
              <Button
                id={""}
                icon={""}
                alt={""}
                value={"Logout"}
                onclick={handleLogout}
                type={"button"}
                className={""}
              />
            </li>
          </ul>
        </div>
      </aside> */}
      <Navbar expand="lg" className="bg-body-tertiary" fixed="top">
        <Container>
          <Navbar.Brand href={`/Profile/:UserID`}>
            Project Management
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href={`/Profile/:UserID`}>Profile</Nav.Link>
              <Nav.Link href={`/TimesheetsPage/:UserID`}>Timesheet</Nav.Link>
              <Nav.Link href={`/ActiveTasks/:UserID`}>Active Tasks</Nav.Link>
              <Nav.Link href={`/ProjectsPage/:UserID`}>Projects</Nav.Link>
              <Nav.Link href={`/Users/:UserID`}>Users</Nav.Link>
              <Nav.Link href={`/Role/:UserID`}>Roles</Nav.Link>
              <Nav.Link
                href={`/Login`}
                onClick={() => {
                  sessionStorage.removeItem("token");
                }}
              >
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}
