import React from "react";
import "./ProjectsPage.scss";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TableProject from "../../components/TableProject/TableProject";
import Button from "../../components/Buttons/Buttons";
import {
  getProfile,
  getUsers,
  getProjectList,
  postProjectAdd,
  deleteProjectList,
  putProjectEdit,
  putProjectUpdate,
  getTimesheetCalendarList,
  getRoles,
} from "../../utils/apiUtils.mjs";
import PopupProjectAdd from "../../components/PopupProjectAdd/PopupProjectAdd";
import Chart from "../../components/Chart/Chart";

const ProjectsPage = () => {
  /* State Variables */
  const [sorting, setSorting] = useState({ item: "id", order: "asc" });
  const [tbodyData, setTbodyData] = useState(null);
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState(null);
  const [timesheetList, setTimesheetList] = useState(null);
  const [rolesList, setRolesList] = useState(null);
  const [projectList, setProjectList] = useState(null);
  const [failedAuth, setFailedAuth] = useState(false);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [addFormData, setAddFormData] = useState({
    project_name: "",
    project_budget: 0,
    project_labour_effort: 0,
    project_revenue: 0,
    user_id: 0,
    client_id: 0,
  });
  const [editFormData, setEditFormData] = useState({
    project_name: "",
    project_budget: 0,
    project_labour_effort: 0,
    project_revenue: 0,
    user_id: 0,
    client_id: 0,
  });
  const [editRowID, setEditRowID] = useState(null);
  const [isBudgetValid, setIsBudgetValid] = useState(false);

  /* Variables */
  const theadData = [
    "Project Manager",
    "Project Name",
    "Project Budget",
    "Project Labour Effort",
    "Project Revenue",
    "Actions",
  ];

  /* Functions */
  const sortTable = (newSorting) => {
    // Sorts table in asscending or descending order
    setSorting(newSorting);
  };

  const getProfileFunction = async () => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      return setFailedAuth(true);
    }

    try {
      // Get the data from the API
      const { data } = await getProfile(token);
      setUser(data);
    } catch (error) {
      setFailedAuth(true);
    }
  };

  const getUsersFunction = async () => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      return setFailedAuth(true);
    }

    try {
      // Get the data from the API
      const { data } = await getUsers(token);
      setUsers(data);
    } catch (error) {
      setFailedAuth(true);
    }
  };

  const getRolesFunction = async () => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      return setFailedAuth(true);
    }

    try {
      // Get the data from the API
      const { data } = await getRoles(token);
      setRolesList(data);
    } catch (error) {
      setFailedAuth(true);
    }
  };

  const getProjectListFunction = async () => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      return setFailedAuth(true);
    }

    try {
      // Get the data from the API
      const { data } = await getProjectList(token);
      setProjectList(data);
      setTbodyData(data);
    } catch (error) {
      setFailedAuth(true);
    }
  };

  const postProjectAddFunction = async (
    id,
    project_name,
    project_budget,
    project_labour_effort,
    project_revenue
  ) => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      return setFailedAuth(true);
    }
    try {
      await postProjectAdd(
        token,
        id,
        project_name,
        project_budget,
        project_labour_effort,
        project_revenue
      );
      await getProjectListFunction();
      setShowModal(false);
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  const putProjectUpdateFunction = async (ProjectID) => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      return setFailedAuth(true);
    }
    try {
      await putProjectUpdate(token, ProjectID);
    } catch (error) {}
  };

  const deleteProjectListFunction = async (ProjectID) => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      return setFailedAuth(true);
    }
    try {
      const { data } = await deleteProjectList(token, ProjectID);
      await putProjectUpdateFunction(ProjectID);
      await getProjectListFunction();
      return data;
    } catch (error) {}
  };

  const putProjectEditFunction = async ({
    id,
    project_name,
    project_budget,
    project_labour_effort,
    project_revenue,
    user_id,
    client_id,
  }) => {
    const token = sessionStorage.getItem("token");

    user_id = user.id;
    if (!token) {
      return setFailedAuth(true);
    }
    try {
      // Get the data from the API

      await putProjectEdit(
        token,
        id,
        project_name,
        project_budget,
        project_labour_effort,
        project_revenue,
        user_id,
        client_id
      );
      await putProjectUpdateFunction(id);
      await getProjectListFunction();
    } catch (error) {}
  };

  const getTimesheetListFunction = async () => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      return setFailedAuth(true);
    }

    try {
      // Get the data from the API
      const { data } = await getTimesheetCalendarList(token);
      setTimesheetList(data);
    } catch (error) {
      setFailedAuth(true);
    }
  };

  /* Validations */
  const isProjectBudgetValid = () => {
    // Project Budget Validation
    if (isNaN(addFormData.project_budget)) {
      return false;
    }
    return true;
  };

  const isFormValid = () => {
    // Form validation
    if (isProjectBudgetValid()) {
      return true;
    }
    return false;
  };

  /* Handle Events */
  const handleAddProject = (event) => {
    setShowModal(true);
  };

  const handleCancel = () => {
    // Handle Cancel Form resets whole form
    setAddFormData({
      project_name: "",
      project_budget: 0,
      project_labour_effort: 0,
      project_revenue: 0,
      user_id: 0,
      client_id: 0,
    });
    setIsBudgetValid(false);
    setShowModal(false);
  };

  const handleAddFormChange = (event) => {
    // Form submit event handler

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();

    if (!isProjectBudgetValid()) {
      setIsBudgetValid(true);
    } else {
      setIsBudgetValid(false);
    }
    if (isFormValid()) {
      postProjectAddFunction(
        user.id,
        event.target.project_name.value,
        event.target.project_budget.value,
        addFormData.project_labour_effort,
        addFormData.project_revenue
      );
      setAddFormData({
        project_name: "",
        project_budget: 0,
        project_labour_effort: 0,
        project_revenue: 0,
        user_id: 0,
        client_id: 0,
      });
    }
  };

  const handleEditFormChange = (event) => {
    // Edit form submit event handler

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };

  const handleEditClick = (event, row) => {
    // Edit button event handler
    event.preventDefault();
    setEditRowID(row.id);

    const formValues = {
      id: row.id,
      project_name: row.project_name,
      project_budget: row.project_budget,
      project_labour_effort: row.project_labour_effort,
      project_revenue: row.project_revenue,
      user_id: row.user_id,
      client_id: row.client_id,
    };
    setEditFormData(formValues);
  };

  const handleDeleteClick = (row) => {
    const newRow = [...tbodyData];
    const index = tbodyData.findIndex((rowID) => {
      return rowID.id === row.id;
    });
    newRow.splice(index, 1);

    deleteProjectListFunction(row.id);
  };

  const handleCancelClick = () => {
    setEditRowID(null);
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();

    const editedRow = {
      id: editFormData.id,
      project_name: editFormData.project_name,
      project_budget: editFormData.project_budget,
      project_labour_effort: editFormData.project_labour_effort,
      project_revenue: editFormData.project_revenue,
      user_id: editFormData.user_id,
      client_id: editFormData.client_id,
    };
    const newRow = [...tbodyData];

    const index = tbodyData.findIndex((rowID) => {
      return rowID.id === editRowID;
    });
    newRow[index] = editedRow;

    putProjectEditFunction(editedRow);
    setEditRowID(null);
  };

  useEffect(() => {
    getProjectListFunction();
    getProfileFunction();
    getTimesheetListFunction();
    getUsersFunction();
    getRolesFunction();
  }, []);

  if (failedAuth) {
    return (
      <main className="project-page">
        <p>You must be logged in to see this page.</p>
        <p>
          <Link to="/Login">Log in</Link>
        </p>
      </main>
    );
  }

  if (!user) {
    return (
      <main className="project-page">
        <p>Loading...</p>
      </main>
    );
  }

  if (user && projectList) {
    const userProject = projectList.filter((project) => {
      return user.id === project.user_id;
    });
    return (
      <main className="project-page">
        <div className="project-page__sub-section">
          <div className="project-page__sub-container">
            <h2 className="project-page__sub-header">Project List</h2>
          </div>
          <TableProject
            theadData={theadData}
            tbodyData={userProject}
            user={user}
            users={users}
            sorting={sorting}
            sortTable={sortTable}
            customClass="table"
            projectList={projectList}
            timesheetList={timesheetList}
            rolesList={rolesList}
            editRowID={editRowID}
            editFormData={editFormData}
            handleEditClick={handleEditClick}
            handleEditFormChange={handleEditFormChange}
            handleEditFormSubmit={handleEditFormSubmit}
            handleCancelClick={handleCancelClick}
            handleDeleteClick={handleDeleteClick}
          />
          {showModal && (
            <PopupProjectAdd
              confirmText="Add Project"
              cancelText="Cancel"
              setTrigger={setShowModal}
              handleAddFormSubmit={handleAddFormSubmit}
              handleAddFormChange={handleAddFormChange}
              addFormData={addFormData}
              projectList={projectList}
              handleCancel={handleCancel}
              isBudgetValid={isBudgetValid}
            />
          )}
          <div className="project-page__sub-container">
            <Button
              id={""}
              icon={""}
              alt={""}
              value={"Add Project"}
              onclick={handleAddProject}
              type={"button"}
              className={""}
            />
          </div>
        </div>
        <div className="project-page__sub-section">
          <div className="project-page__sub-container">
            <h2 className="project-page__sub-header">Project Metrics</h2>
          </div>
          <div className="project-page__sub-container">
            <Chart userProject={userProject} />
          </div>
        </div>
      </main>
    );
  }
};

export default ProjectsPage;
