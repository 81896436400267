import React from "react";
import "./LoginPage.scss";
import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import Input from "../../components/Input/Input";
import Button from "../../components/Buttons/Buttons";
import Textbox from "../../components/Textbox/Textbox";
import { postLogin } from "../../utils/apiUtils.mjs";

const LoginPage = () => {
  const Navigate = useNavigate();

  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { data } = await postLogin(
        event.target.email.value,
        event.target.password.value
      );
      sessionStorage.setItem("token", data.token);
      Navigate("/Profile/:UserID");
    } catch (error) {
      setError(error.response.data);
    }
  };

  return (
    <main className="login-page">
      <div className="login-page__sub-section">
        <div className="login-page__sub-container">
          <h2 className="login-page__sub-header">Enter your credentials</h2>
        </div>
        <div className="login-page__sub-container">
          <form onSubmit={handleSubmit} className="login-page__form">
            <Input
              type="text"
              name="email"
              label="Email"
              placeholder="Enter your email"
            />
            <Input
              type="password"
              name="password"
              label="Password"
              placeholder="Enter your password"
            />

            <Button
              type="submit"
              value="Log in"
              className="login-page__button"
            />
            <p>
              Need an account? <Link to="/Signup">Sign up</Link>
            </p>
            {error && <div className="login__message">{error}</div>}
          </form>
        </div>
      </div>
    </main>
  );
};

export default LoginPage;
