import "../Buttons/Buttons.scss";

/*
  Buttons component 
*/

const Buttons = ({ id, icon, alt, value, onclick, type, className }) => {
  return (
    <div className="button" id={id}>
      <button
        className={`button__class ${className}`}
        onClick={onclick}
        type={type}
      >
        {value}
      </button>
    </div>
  );
};

export default Buttons;
